function routeHook(to, from, next) {
    if (
        [
            "CategoryInfo",
            "ProductInfo",
            "BrandInfo",
            "SellerInfo",
            "KeywordInfo",

            "ListView",
            "Highlights",
        ].includes(from.name) &&
        ((from.query.date && !to.query.date) ||
            (from.query.date2 && !to.query.date2))
    ) {
        const newQuery = {
            ...to.query,
        };
        if (from.query.date && !to.query.date) {
            newQuery.date = from.query.date;
        }
        if (from.query.date2 && !to.query.date2) {
            newQuery.date2 = from.query.date2;
        }
        next({
            ...to,
            query: newQuery,
        });
        return;
    }
    next();
}

import {
    dateRangeFromDate,
    dateRangeBoundaries,
    dateRangeUnsafe,
    today,
} from "@/components/RangeCalendar.vue";
import CalendarMixin from "./Calendar";

export default {
    mixins: [CalendarMixin],
    data() {
        return {
            // value that you can use in your component
            // calendar: null,
            calendarInternal: null,
        };
    },
    beforeRouteEnter: routeHook,
    beforeRouteUpdate: routeHook,
    computed: {
        calendarKey() {
            return `${this.getMP()}:${this.$route.query?.date}:${
                this.$route.query?.date2
            }`;
        },
        calendar: {
            get: function () {
                return this.calendarInternal;
            },
            set: function (v) {
                if (
                    v &&
                    (!this.$route.query ||
                        this.$route.query.date !== v[0] ||
                        this.$route.query.date2 !== v[1])
                ) {
                    this.$router.replace(
                        {
                            query: {
                                ...this.$route.query,   //! &:
                                date: v[0],
                                date2: v[1],
                            },
                        },
                        () => {
                            this.calendarInternal = v;
                        },
                        () => {
                            this.calendarInternal = v;
                        }
                    );
                } else {
                    this.$set(this, "calendarInternal", v);
                }
            },
        },
        fbs: {
            get() {
                if (this.$route.query.fbs === "0") {
                    return 0;
                }
                return this.$route.query.fbs ? 1 : 0;
            },
            set(v) {
                const value = v ? 1 : 0;
                if (this.$route.query.fbs !== value) {
                    const query = { ...this.$route.query };
                    query.fbs = value;
                    this.$router.replace({
                        ...this.$route,
                        query,
                    });
                }
            },
        },
    },
    methods: {
        async loadCalendar() {

            //console.log('110: this.route=', this.$route)

            if (!this.calendarBoundaries) {
                this.calendar = null;
                return;
            }

            let iPeriod=30;
            //if( this.$route.name==='priceVar' ){
            if( this.$route.name==='retail_price_monitoring' ){
                iPeriod=1;
            }

            const lastAvailableDate = this.calendarBoundaries?.[1] || today();
            const weekRange = dateRangeFromDate(lastAvailableDate, iPeriod);
            const queryRange = dateRangeUnsafe(
                this.$route.query?.date,
                this.$route.query?.date2
            );
            this.calendar = dateRangeBoundaries(
                [queryRange[0] || weekRange[0], queryRange[1] || weekRange[1]],
                this.calendarBoundaries
            );
        },
    },
    watch: {
        calendarBoundaries() {
            this.loadCalendar();
        },
        calendarKey() {
            this.loadCalendar();
        },
    },
};
