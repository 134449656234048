var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('button', {
    staticClass: "btn-outline btn-width-auto",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClick.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/save.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" Добавить в список "), _c('modal-list-add-items', {
    ref: "modalListAddItems"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };